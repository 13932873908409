<template>
  <b-container>
    <b-row>
      <b-col cols="12">
        <b-card>
          <b-container>
            <b-row>
              <b-col cols="12">
                <zbar-scanner @decode="onDecode"/>
              </b-col>
            </b-row>
          </b-container>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import ZbarScanner from '../components/ZBarScannerEdit.vue';

export default {
  components: {
    'zbar-scanner': ZbarScanner,
  },
  methods: {
    onDecode(result) {
      alert(`Scanner Receive ${result}`)
    }
  }
}
</script>
