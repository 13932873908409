<template>
  <div class="camera-container">
    <video
      id="cameraStream"
      v-if="useCamera !== null"
    />

    <select v-if="canEnumerateDevices" @change="doChangeCamera" v-model="camera">
      <option v-for="enumCamera in cameras" :value="enumCamera">{{ enumCamera.label }}</option>
    </select>
    <!-- <div id="camera-overlay" class="overlay-element" v-once></div>
    <div class="laser" v-once></div> -->
  </div>
</template>

<script>
import { scanImageData } from 'zbar.wasm';

export default {
  data() {
    return {
      videoEl: null,
      overlay: null,
      shouldStart: true,
      useCamera: null,
      camera: null,
      cameras: [],
      cameraChange: false,
      stream: null,
      startWithoutCamera: false,
      cameraStart: false,
    }
  },
  destroyed() {
    this.shouldStart = false;
  },
  mounted() {
    if (this.isMediaDevicesSuported()) {
      if (this.canEnumerateDevices()) {
        navigator.mediaDevices.enumerateDevices().then((cameras) => {
          let cameraResult = [];

          for (let c of cameras) {
            let kind = c.kind === 'video' ? 'videoinput' : c.kind;

            if (kind !== 'videoinput') {
              continue;
            }

            let cameraDeviceId = c.deviceId || c.id;
            let cameraLabel = c.label || `Camera Device ${cameraResult.length + 1}`;
            let groupId = c.groupId;

            cameraResult.push({
              deviceId: cameraDeviceId,
              label: cameraLabel,
              groupId: groupId,
            });
          }

          this.cameras = cameraResult;
        });
      } else {
        this.startWithoutCamera = true;
        this.useCamera = '';
        this.$nextTick(() => {
          this.zbarStarCamera();
        })
      }
    } else {
      alert('Access Camera Not Supported In This Browser')
    }
  },
  methods: {
    hasNavigator() {
      return typeof navigator !== 'undefined';
    },
    isMediaDevicesSuported() {
      return this.hasNavigator() && !!navigator.mediaDevices;
    },
    canEnumerateDevices() {
      return !!(this.isMediaDevicesSuported() && navigator.mediaDevices.enumerateDevices);
    },
    doChangeCamera() {
      this.changeCamera = true;
      this.useCamera = true;

      this.$nextTick(() => {
        this.shouldStart = true;

        if (this.cameraStart) {
          try {
            this.videoEl.srcObject = null;
          } catch (e) {
            this.videoEl.src = '';
          }

          this.videoEl.removeAttribute('src');
        }

        this.zbarStarCamera();
      })
    },
    zbarStarCamera() {
      const _this = this;

      _this.videoEl = document.getElementById('cameraStream')
      _this.overlay = document.getElementById('camera-overlay')

      if (this.cameraStart) {
        _this.videoEl.pause();
        _this.closeStream();
      }

      console.log(this)

      let constraints = {
        audio: false,
        video: {
         facingMode: this.startWithoutCamera ? 'environment' : undefined,
         width: { max: 720 },
         height: { max: 720 },
         deviceId: this.startWithoutCamera ? undefined : { exact: this.camera.deviceId },
        }
      };

      console.log(constraints)

      navigator.mediaDevices.getUserMedia(constraints).then(stream => {
        _this.videoEl.setAttribute('playsinline', '');
        _this.videoEl.srcObject = stream;
        _this.videoEl.setAttribute('playsinline', '');
        _this.videoEl.play();

        _this.stream = stream;

        _this.videoEl.onloadedmetadata = _this.loadMetadata;
        _this.videoEl.onplay = _this.fixVideoEl;
      });
    },
    fixVideoEl() {
      this.videoEl.style.height = 'auto';
      this.videoEl.style.width = '100%';
    },
    loadMetadata() {
      if (!this.cameraStart) {
        this.zbarLoop();
        this.cameraStart = true;
      }
    },
    closeStream() {
      if (this.stream !== null) {
        this.stream.getTracks().forEach(function(track) {
          track.stop();
        });

        this.stream = null;
        console.log('Close Stream')
      }
    },
    async zbarLoop() {
      while (this.shouldStart) {
        await this.zbarDoScan();
        await this.doSleep(800);
      }

      this.closeStream();
      console.log('Finish Read Camera')
    },
    async zbarDoScan() {
      const w = this.videoEl.videoWidth;
      const h = this.videoEl.videoHeight;

      this.canvas = document.createElement('canvas');

      this.canvas.width = w;
      this.canvas.height = h;
      const ctx = this.canvas.getContext('2d');

      if (w > 0 && h > 0) {
        ctx.drawImage(this.videoEl, 0, 0, w, h);

        this.rawImageData = ctx.getImageData(0, 0, w, h);

        // console.log(imgData);
        const res = await scanImageData(this.rawImageData);

        if (res.length > 0) {
          this.$emit('decode', res[0].decode());
        }
      }
    },
    doSleep(timeout) {
      return new Promise((resolve, reject) => {
        setTimeout(() => { resolve() }, timeout)
      })
    },
  },
  computed: {
    isStart() {
      return this.start;
    }
  }
}
</script>

<style scoped>
.camera-container {
  position: relative;
}

.overlay-element {
  position: absolute;
  top: 0;
  width: 100%;
  height: 99%;
  background: rgba(30, 30, 30, 0.5);
  -webkit-clip-path: polygon(
    0% 0%,
    0% 100%,
    20% 100%,
    20% 20%,
    80% 20%,
    80% 80%,
    20% 80%,
    20% 100%,
    100% 100%,
    100% 0%
  );
  clip-path: polygon(
    0% 0%,
    0% 100%,
    20% 100%,
    20% 20%,
    80% 20%,
    80% 80%,
    20% 80%,
    20% 100%,
    100% 100%,
    100% 0%
  );
}
.laser {
  width: 60%;
  margin-left: 20%;
  background-color: tomato;
  height: 1px;
  position: absolute;
  top: 40%;
  z-index: 2;
  box-shadow: 0 0 4px red;
  -webkit-animation: scanning 2s infinite;
  animation: scanning 2s infinite;
}
@-webkit-keyframes scanning {
  50% {
    -webkit-transform: translateY(75px);
    transform: translateY(75px);
  }
}
@keyframes scanning {
  50% {
    -webkit-transform: translateY(75px);
    transform: translateY(75px);
  }
}
</style>
